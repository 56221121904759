import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/Layout"
import Item from "../components/Item"
import Pagination from "../components/Pagination"
import styles from "../styles/modules/tagsTemplate.module.sass"

export default ({
  data: {
    allMarkdownRemark: { totalCount, edges: blogs },
  },
  pageContext,
}) => {
  return (
    <Layout frontmatter={null}>
      <div className="slipnote-section">
        <h2 className={styles.title}>
          <img
            className={styles.icon}
            src={`/assets/logo/${pageContext.tag.toLowerCase()}.png`}
            alt={pageContext.tag}
          />
          {pageContext.tag}
        </h2>
        {blogs.map(
          ({
            node: {
              id,
              html,
              frontmatter: { title, tags, date, description, thumbnail },
              fields: { slug },
            },
          }) => (
            <Item
              key={`item_${id}`}
              date={date}
              slug={slug}
              title={title}
              description={description}
              tags={tags}
              thumbnail={thumbnail.childImageSharp.fluid}
            />
          )
        )}

        <div>
          <Pagination pageContext={pageContext} />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($tag: String!, $skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          id
          html
          frontmatter {
            title
            date(formatString: "YYYY年MM月DD日")
            tags
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 820) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
